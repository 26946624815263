<template>
    <div>
        <router-view />
    </div>
</template>

<script>
import emitter from '@/utils/bus'
export default {
    watch: {
        $route: {
            handler(newRoute, oldRoute) {
                if(newRoute.path == "/login") {
                    return
                }
                if(oldRoute &&  oldRoute.path == '/login' && newRoute.path == "/index") {
                    return
                }
                emitter.emit('getVersion')
            },
            immediate: true
        }
    },
    computed: {
        key: function() {
            return this.$route.fullPath
        }
    }
}
</script>