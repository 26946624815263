<template>
  <div>
    <footer class="footer">
      <div class="container content">
        <div class="copyright">
          <div class="line-left">
            <div class="head">公司分布：</div>
            <div class="content">
              <div class="left">
                <p>山 东：青岛市市南区山东路6号丁华润大厦A座26F</p>
                <p>上 海：上海市杨浦区黄兴路2077号2103-2106室</p>
              </div>
              <div class="right">
                <p>北 京：北京市朝阳区祁家豁子8号健翔大厦416室</p>
                <p>河 南：郑州市郑东新区永和龙子湖广场A座3412</p>
              </div>
            </div>
          </div>
        </div>
        <div class="contact">
          <div class="telephone">
            <div class="tel-head">全国统一合作热线</div>
            <div class="tel-num">400-601-0039</div>
            <div class="code">
              <a href="https://beian.miit.gov.cn" target="_blank" class="code">备案号：鲁ICP备07010079号-3</a>
            </div>
          </div>
          <div class="qrcodes">
            <div class="code">
              <div class="code-img">
                <img src="../../assets/images/qr1.png" alt="" />
              </div>
              <div class="code-head">官方微信公众号</div>
            </div>
            <div class="code">
              <div class="code-img">
                <img src="../../assets/images/qr2.png" alt="" />
              </div>
              <div class="code-head">官方微信视频号</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>
<style lang="scss" scoped>
.footer {
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    height: 119px;
    box-sizing: border-box;
    background-color: #000;
    .line-left {
      p {
        margin: 0;
        line-height: 20px;
      }
      .head {
          color: #d2d2d2;
          // font-size: 14px;
          line-height: 30px;
      }
      .content {
        display: flex;
        align-items: flex-start;
        letter-spacing: 1px;
        .right {
          margin-left: 30px;
        }
      }
    }
    .content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .copyright {
        font-size: 12px;
        color: #999999;
        line-height: 23px;
      }

      .contact {
        display: flex;
        align-items: center;

        .telephone {
          margin-right: 64px;

          .tel-head {
            color: #a1a1a1;
            font-size: 14px;
            line-height: 30px;
          }
          .code {
            color: #a1a1a1;
            font-size: 14px;
            line-height: 30px;
            &:hover {
              text-decoration: underline;
            }
          }

          .tel-num {
            color: #ff6806;
            font-size: 20px;
          }
        }

        .qrcodes {
          display: flex;

          .code {
            display: flex;
            flex-direction: column;
            align-items: center;

            & + .code {
              margin-left: 30px;
            }

            .code-img {
              width: 71px;
              height: 71px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .code-head {
              padding-top: 5px;
              font-size: 12px;
              color: #a1a1a1;
            }
          }
        }
      }
    }
  }
</style>
