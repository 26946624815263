<template>
  <div class="book-marks-wrapper">
    <el-dialog
      custom-class="select-book-wrapper"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="280px"
      :before-close="handleClose"
    >
      <template #title>
        <div class="my-header">
          <div class="title">选择收藏夹</div>
        </div>
      </template>

      <template #default>
        <div class="marks-wrapper">
          <div class="title">收藏到：</div>
          <div class="marks-list">
            <div
              :class="
                marksFlag == item.id
                  ? 'marks-item marks-item-avtived'
                  : 'marks-item'
              "
              v-for="item in remarks"
              :key="item.id"
              @click="selectMarksItem(item)"
            >
              <div class="left">
                <span class="iconfont icon-jdfiles"></span>
                <div class="name">{{ item.name }}</div>
              </div>
              <div class="right" v-if="marksFlag == item.id">
                <el-button
                  round
                  class="collect-btn"
                  size="small"
                  @click="toSdCreate(item)"
                >
                  收藏
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="dialog-footer" @click.stop="addMarks">
          <div class="img-wrapper">
            <img :src="addIcon" alt="" srcset="" />
          </div>
          <div class="text">创建新收藏夹</div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";

import { MessageBox } from "element-ui";

import { collectGroup, addCollectGroup } from "@/api/ai/sd/collect";

export default {
  setup(props, { emit }) {
    let state = reactive({
      dialogVisible: true,
      addIcon: require("@/assets/images/imgs/add.png"),
      marksFlag: 1,
      remarks: [],
    });

    onMounted(() => {
      getCollectList();
    });

    const getCollectList = async () => {
      let { code, data } = await collectGroup();
      if (code == 200) {
        state.remarks = data;
      }
    };

    const handleClose = () => {
      emit("closeDialog");
    };

    const selectMarksItem = (item) => {
      state.marksFlag = item.id;
    };

    const addMarks = async () => {
      let info = await MessageBox.prompt("收藏夹名称", "新增收藏夹", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        customClass: 'sd-messagebox',
        center: true,
      }).catch((error) => error);

      if (info.value) {
        let { code, data } = await addCollectGroup({ name: info.value });

        if (code == 200) {
          getCollectList();
        }
      }
    };

    const toSdCreate = (item) => {
      emit("collectGroupId", item);
      emit("closeDialog");
    };

    return {
      ...toRefs(state),
      handleClose,
      selectMarksItem,
      addMarks,
      toSdCreate,
    };
  },
};
</script>

<style lang="scss">
// .book-marks-wrapper {
  .select-book-wrapper {
    height: 400px;
    display: flex;
    flex-direction: column;
    background: #373737!important;
    border: 2px solid #4f4f4f;
    box-shadow: 0px 13px 27px 5px rgba(10, 30, 39, 0.81);
    border-radius: 14px;
    .collect-btn {
      background-color: #fc6804;
      border: none;
      padding: 6px 12px;
      &:hover {
        filter: brightness(110%);
      }
    }
    .el-dialog__header {
      padding: 0px 15px 0px 15px;
    }
    .el-dialog__headerbtn {
      top: 6px;
      right: 6px;
      .el-dialog__close {
        font-size: 20px;
        padding: 4px;
        background: #212121;
        border-radius: 50%;
      }
    }
    .my-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      padding-right: 20px;
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #e9e9e9;
      }

      .input-with-select {
        width: 300px;
      }
    }

    .el-dialog__body {
      padding: 0px 10px;
      flex-grow: 1;
      height: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    .el-dialog__footer {
      padding: 12px 20px;
      cursor: pointer;
    }
    .dialog-footer {
      border-top: 2px solid #4f4f4f;
      display: flex;
      align-items: center;
      padding-top: 12px;
      .img-wrapper {
        width: 32px;
        height: 32px;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        color: #fc6804;
        margin-left: 12px;
      }
    }
    .marks-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 0;
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #666565;
        margin-bottom: 10px;
        padding-left: 8px;
      }
      .marks-list {
        flex-grow: 1;
        overflow: auto;
        padding-bottom: 10px;
        .marks-item:hover {
          background: #272727;
        }
        .marks-item {
          cursor: pointer;
          padding: 9px 8px;
          border-radius: 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.marks-item-avtived {
            background: #272727;
          }
          .left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
              margin-left: 10px;
              color: #a3a3a3;
              line-height: 24px;
            }
          }

          .right {
            button {
              color: #fff;
            }
          }
        }
      }
    }
  }
// }

</style>

<style></style>

