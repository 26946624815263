<template>
  <el-dialog
    custom-class="version-dialog"
    :visible.sync="dialogVisible"
    width="440px">
    <div slot="title" class="title">
      <div class="banner"></div>
      <p class="version-head">{{ versionMessage.versionId }}</p>
    </div>
    <div class="content">
      <div v-html="versionMessage.content"></div>
    </div>
    <span slot="footer">
      <el-button class="button" @click="dialogVisible = false">立即体验</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isReadVersion, getVersion } from '@/api/main/version'
import emitter from '@/utils/bus'

export default {
  data() {
    return {
      dialogVisible: false,
      versionMessage: {}
    }
  },
  beforeCreate() {
  },
  created() {
    this.hasReadVersion()
    emitter.on('getVersion', e => {
      this.hasReadVersion()
    })
  },
  methods: {
    hasReadVersion() {
      isReadVersion().then(({code, data}) => {
        if(data) {
          this.dialogVisible = true
          this.versionMessage = data
          getVersion(this.versionMessage.id).then(res => {
            console.log(res)
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.title {
  .banner {
    height: 166px;
    background-image: url("@/assets/images/pub/version.png");
    background-size: cover;
    background-position: center;
  }
  .version-head {
    font-size: 22px;
    color: #F3F3F3;
    text-align: center;
  }
  
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 3px;
}
.content {
  max-height: 300px;
  overflow: auto;
  padding-right: 5px;
  color: #D3D3D3!important;
  font-size: 16px!important;
  margin-top: 0!important;
  margin-bottom: 14px!important;
  p {
    color: #D3D3D3!important;
    font-size: 16px!important;
    margin-top: 0!important;
    margin-bottom: 14px!important;
  }
}
.button {
  width: 174px;
  height: 28px;
  background-color: #EE832F!important;
  border-radius: 28px;
  color: #F3F3F3!important;
  font-size: 20px;
  padding: 0;
  border: none;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 0;
  padding-left: 18px;
  padding-right: 13px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
:v-deep .el-icon-close {
  color: #fff!important;
  font-size: 16px;
}
</style>

<style lang="scss">
.version-dialog {
  background-color: #373737!important;
  border-radius: 16px!important;
}
</style>@/api/main/version