<template>
  <div class="my-headline container">
    <h2 class="my-head">{{ headline }}</h2>
    <!-- <div class="option" v-show="downAvailable">
      <el-button v-show="!mode" class="option-btn" @click="handleChangeMode(true)">批量下载</el-button>
      <el-button v-show="mode" class="option-btn">下载</el-button>
      <el-button v-show="mode" class="option-btn option-light" @click="handleChangeMode(false)">取消</el-button>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
        searchValue: '',
        selectId: 0   
    }
  },
  props: {
    headline: {
      default: "",
    },
    searchBar: {
      default: false,
    },
    searchHolder: {
      default: "",
    },
    filterTagName: {
      default: null,
    },
    hasSelect: {
      default: false
    },
    selectOptions: {
      default: () => {
        return []
      }
    },
    selectKey: {
      default: 'label'
    },
    selectValue: {
      default: 'value'
    },
    mode: {
      default: false // false 非选择模式
    },
    downAvailable: {
      default: false
    }
  },
  methods: {
    handleSearch() {
      this.$emit('handleSearch', this.searchValue)
    },
    handleChangeMode(state) {
      this.$emit('modeChange', state)
    }
  }
};
</script>
<style lang="scss" scoped>
.my-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;
}
.my-head {
  margin: 13px 0 13px;
  font-size: 23px;
  font-weight: normal;
  letter-spacing: 4px;
  color: #fc6804;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option {
  display: flex;  
  .option-btn {
    font-size: 16px;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    background-color: #F96B26;
    color: #ffffff;
    letter-spacing: 4px;
    padding-left: 18px;
    padding-right: 14px;
    &:hover {
      filter: brightness(95%);
    }
  }
  .option-light {
    background-color: #EADCCF;
  }
}
.filter-select {
  height: 100%;
  background-color: #CCBAAB;
  ::v-deep .el-input {
    height: 100%;
    background-color: #CCBAAB;
  }
  ::v-deep .el-input__inner {
    font-size: 14px;
    height: 100%;
    background-color: #CCBAAB;
    border: none;
    color: #fff;
    text-align: center;
    width: 150px;

  }
  
  ::v-deep .el-input__inner::placeholder {
    color: #fff;
  }
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #fff;
  }
  ::v-deep .el-input__inner::-moz-placeholder {
    color: #fff;
  }
  ::v-deep .el-input__inner::-ms-input-placeholder {
    color: #fff;
  }
  ::v-deep .el-select__caret {
    color: #fff;
  }
}

</style>
