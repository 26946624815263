<template>
  <div>
    <!-- 图片预览 -->
    <!-- <viewer :images="[imagePath]">
      <img v-for="(src, index) in [imagePath]" :key="index" :src="src" />
    </viewer> -->
    <!-- <el-image style="display: none" ref="previewImg" :preview-src-list="[imagePath]"></el-image> -->

    <!-- 视频预览 -->
    <el-dialog
      :visible.sync="videoVisible"
      width="1000px"
      center
      class="videoVisible"
      :close-on-click-modal="false"
      @close="closeVideoView"
    >
      <div class="video">
        <video
          :src="videoPath"
          ref="videoPlayer"
          class="videoPlayer"
          controls
          controlslist="nodownload"
          @contextmenu.prevent
        ></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
import { getFileUrl } from "@/plugins/download";

export default {
  props: ["fileType", "filePath"],
  data() {
    return {
      // 图片预览
      imagePath: "",

      // 视频预览
      videoPath: "",
      videoVisible: false,

      // CUR
      curFileType: "",
      curFilePath: "",

      // 预览格式支持
      viewerApiList: ["jpg", "png", "jpeg", "gif", "webp", "dwg"],
      kkFileViewList: ["txt", "zip", "rar", "gzip", "mp3", "mp4", "flv", "wav"],
      officeWList: [
        "doc",
        "dot",
        "wps",
        "wpt",
        "docx",
        "dotx",
        "docm",
        "dotm",
        "rtf",
        "txt",
        "xml",
        "mhtml",
        "mht",
        "html",
        "htm",
        "uof",
      ],
      officeSList: [
        "xls",
        "xlt",
        "et",
        "xlsx",
        "xltx",
        "csv",
        "xlsm",
        "xltm",
        "ett",
      ],
      officePlist: [
        "ppt",
        "pptx",
        "pptm",
        "ppsx",
        "ppsm",
        "pps",
        "potx",
        "potm",
        "dpt",
        "dps",
        "pot",
      ],
      officeFlist: ["pdf"],

      viewer: null,
    };
  },
  mounted() {},
  methods: {
    /**
     *
     * @param {*} fileType
     * @param {*} message 文件信息 id 或 previewImage
     * @param {*} type   1 bos-id, 2 previewImage 3.其他id
     */
    async handlePreview(fileTypeOrigin, message, type = 2, prefix = "bos_") {
      console.log(message);

      let filePath, fileId, fileType;
      fileType = fileTypeOrigin.toLowerCase();

      // 预处理
      if (type == 1) {
        fileId = message;
        const { data } = await getFileUrl(message);
        filePath = data;
      } else if (type == 2) {
        filePath = message;
        if (filePath) {
          this.viewer = this.$viewerApi({
            images: [filePath],
          });
        }
        return;
      } else {
        fileId = message;
      }

      if (!filePath && !fileId) {
        this.$message.info("暂无文件");
        return;
      }

      // 预览方式选择
      const ways = [
        "viewerApiList",
        "kkFileViewList",
        "officeWList",
        "officeSList",
        "officePlist",
        "officeFlist",
      ];
      let previewWayIndex;
      for (let w = 0; w < ways.length; w++) {
        if (this[ways[w]].includes(fileType)) {
          previewWayIndex = w;
          break;
        }
      }
      switch (previewWayIndex) {
        case 0:
          if (filePath) {
            this.viewer=this.$viewerApi({
              images: [filePath],
            });
          } else {
            this.$message.error("暂无预览图");
          }

          break;
        case 1:
          this.kkFileView(filePath);
          break;
        case 2:
          window.open(
            this.$router.resolve({ path: `/office/Writer/${prefix + fileId}` })
              .href,
            "_blank"
          );
          break;
        case 3:
          window.open(
            this.$router.resolve({
              path: `/office/Spreadsheet/${prefix + fileId}`,
            }).href,
            "_blank"
          );
          break;
        case 4:
          window.open(
            this.$router.resolve({
              path: `/office/Presentation/${prefix + fileId}`,
            }).href,
            "_blank"
          );
          break;
        case 5:
          window.open(
            this.$router.resolve({ path: `/office/Pdf/${prefix + fileId}` })
              .href,
            "_blank"
          );
          break;
        default:
          this.$message.error("该类型的文件暂不支持预览");
          break;
      }
    },
    // 图片预览
    previewImage(path) {
      this.imagePath = path;
      // this.$refs.previewImg.showViewer = true;
    },
    // 视频预览
    previewVideo(path) {
      this.videoPath = path;
      this.currentFileUrl = path;
      this.videoVisible = true;
      this.$nextTick(() => {
        // this.$refs.videoPlayer.play();
      });
    },
    closeVideoView() {
      this.$nextTick(() => {
        this.$refs.videoPlayer.pause();
      });
    },
    // 其他预览
    kkFileView(path) {
      console.log(1);
      window.open(
        `http://120.48.180.33:8012/onlinePreview?url=${encodeURIComponent(
          Base64.encode(path)
        )}`
      );
    },
    viewerhide() {
      if(this.viewer) 
      this.viewer.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
// 视频预览
::v-deep .videoVisible .el-dialog {
  background: rgba(222, 222, 222, 0.1) !important;
  box-shadow: none;
  // border-radius: 5px;

  .el-dialog__close {
    font-size: 20px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.5);

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.videoPlayer {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
