import { render, staticRenderFns } from "./loading3.vue?vue&type=template&id=5ccde4a7&"
var script = {}
import style0 from "./loading3.vue?vue&type=style&index=0&id=5ccde4a7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports