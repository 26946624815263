<template>
  <div class="graphic">
    <div class="container graphic-list">
			<div class="graphic-item" :title="item[name]" v-for="item, index in graphicList" :key="index" @click="handleGetItem(item)">
				<div class="gold-case" v-show="item.caseLevelType == 2">金牌项目</div>
				<div class="graphic-image" :class="{'graphic-image-filter': downMode}">
					<div class="img__inner" :style="{backgroundImage: `url(${item[path] || $defaultUrl})`, paddingBottom: aspectRatio}"></div>
					<div class="loading-mask" v-show="item.loading">
						<div class="circle-side"></div>
					</div>
					<div v-if="mask" class="mask">
						<div class="mask-inner">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
							<div class="bottom">
								<button class="btn btn-eye" @click="handlePreview(item)">
									<div class="icon icon-eye"></div>
									<span>预览</span>
								</button>
								
								<button class="btn btn-download" @click="handleDownload(item)">
									<div class="icon icon-down"></div>
									<span>下载</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="graphic-message">
					<span v-if="item[firstName]" class="title">
						{{ item[firstName] | nameFilter(item, firstName) }}
					</span>
					<span v-else class="title">{{ item[name] | nameFilter(item, name) }}</span>
				</div>
			</div>
		</div>
		<previews ref="previewRef"></previews>
  </div>
</template>

<script>
import previews from '../Pub/previews.vue';
export default {
	data() {
		return {
			url: 'http://120.48.178.60:9000/goldon-cloud-icon/other.jpg'
		}
	},
	components: {
		previews
	},
  props: {
		firstName: {
			default: 'name'
		},
		name: { // 名称字段
			default: 'name'
		},
		path: { // 预览图字段
			default: 'path'
		},
		aspectRatio: { // 宽高比
			default: '56.25%'
		},
    graphicList: { // 列表
      default: () => [],
    },
		mask: {
			default: false
		},
		downloadable: {
			default: false
		},
		previewable: {
			default: false
		},
		downloadName: {
			default: ''
		},
		previewName: {
			default: ''
		},
		downMode: {
			default: false
		}
  },
	filters: {
		nameFilter(originName, item, name) {
			if(item.aliasName && name == 'aliasName') {
				const index = item.aliasName.lastIndexOf('.')
				const fileSuffix = item.aliasName.slice(index + 1)
				if(fileSuffix && fileSuffix.toLowerCase() == item.fileType) {
					return item.aliasName.slice(0, index)
				} else {
					return item.aliasName
				}
			} else {
				return item[name]
			}
		}
	},
	methods: {
		handleGetItem(item) {
			this.$emit('handleViewItem', item)
		},
		handlePreview(item) {
			const suffix = item.templateUrl ? item.templateUrl.slice(item.templateUrl.lastIndexOf('.') + 1) :  null
			this.$refs.previewRef.handlePreview(suffix, item.id, 3, 'form_')
		},
		handleDownload(item) {
			window.open(item[this.downloadName], '_blank')
		}
	}
};
</script>
<style lang="scss" scoped>
.gold-case {
	position: absolute;
	z-index: 3;
	background-image: url('@/assets/images/case/gold.png');
	width: 103px;
	height: 45px;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-size: contain;
	color: #FFF6C2;
	font-size: 12px;
	padding-left: 40px;
	padding-top: 11px;
}
.graphic-image-filter {
	
}
	.graphic-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: 35px;
		.graphic-item {
			padding: 5px;
			box-sizing: border-box;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			&:hover {
				background-color: #E8D7C9;
				.mask {
					display: block !important;;
				}
				.img__inner {
					// background-size: contain!important;
				}
			}
			width: 18.464%;
			flex: 0 0 18.464%;
			span {
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			margin-left: 1.92%;
			margin-bottom: 25px;
			&:nth-child(5n + 1) {
				margin-left: 0;
			}
			.graphic-image {
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
				.mask {
					display: none;
					position: absolute;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.3);
					top: 0;
					left: 0;
					border-radius: 6px;
					.mask-inner {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
					}
					.bottom {
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
					}
					.btn {
						width: 50%;
						height: 10%;
						background-color: #fff;
						color: #936F51;
						border-radius: 4px;
						font-size: 1.15rem;
						border-color: #fff;
						display: flex;
						justify-content: center;
						align-items: center;
						letter-spacing: 0.5rem;
						padding-left: 0.5rem;
						margin-bottom: 10px;
						&.btn-eye {
							box-sizing: border-box;
							background-color: #fff;
							color: #7B7B7B;
							// border: 1px solid #7B7B7B;
							&:hover {
								border: 1px solid #F96B26;
								border-color: #F96B26;
								color: #F96B26;
								& .icon-eye {
									background-image: url(@/assets/images/chalets/eye-active.png);
								}
							}
						}
						&.btn-download {
							background-color: #F96B26;
							color: #FFF9F6;
							&:hover {
								background-color: #FB7D3F;
							}
						}
						
						.icon {
							width: 16px;
							height: 16px;
							background-size: contain;
							
							margin-right: 10px;
							&.icon-eye {
								background-image: url(@/assets/images/chalets/eye.png);
							}
							&.icon-down {
								background-image: url(@/assets/images/chalets/download.png);
							}
						}
					}
				}
				.img__inner {
					border-radius: 6px;
					width: 100%;
					padding-bottom: 56.25%;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					transition: all .3s linear;
					-webkit-transition: background-size .3s linear;
				}
				img {
					border-radius: 6px;
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;
				}
			}
			.graphic-message {
				overflow: hidden;
				text-overflow: ellipsis;
				margin-top: 5px;
				margin-bottom: 7px;
				font-size: 1.15rem;
				font-family: SourceHanSansCNMedium;
				color: #5B5B5B;
				box-sizing: border-box;
				padding: 0 10px 0 3px;
				.title {
					cursor: pointer;
				}
			}
		}
	}
</style>
