<template>
  <span class="input-text">
    <span>[</span>
    <div
      :class="{ wink: editable }"
      class="input-content editor"
      :contenteditable="editable"
      placeholder="添加描述符"
      @focus="ischecked = true"
      @blur="blurFn"
      @input="inputFn"
      @paste.prevent="textInit"
      @keydown.enter.prevent
      v-html="text"
      style="color: #666666 !important; font-size: 16px !important"
    ></div>
    
    <span>]</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      // 解决光标跳动BUG
      if (!this.ischecked) {
        this.text = this.formatString(this.value);
      }
    },
  },
  data() {
    return {
      ischecked: false,
      text: this.value,
    };
  },
  methods: {
    formatString(str) {
      return str.replace(/<[^>]+>/g, "").replace(/&nbsp;/gi, "");
    },
    inputFn(e) {
      const val = this.formatString(e.target.innerHTML);
      this.$emit("input", val);
    },
    blurFn(e) {
      this.ischecked = false;
      this.text = this.value;
      e.view.blur();
    },
    textInit(e) {

      // 重写paste
      var text;
      var clp = (e.originalEvent || e).clipboardData;

      if (clp === undefined || clp === null) {
        text = window.clipboardData.getData("text") || "";
      } else {
        text = clp.getData("text/plain") || "";
      }

      if (text !== "") {
        text = text.replace(/(\r\n|\r|\n)/g, "\n");
        // 去除空
        let text_arr = text.split("\n");
        text_arr = text_arr.filter(function (e) {
          return e;
        });
        let new_text = text_arr.join("\n");
        if (window.getSelection) {
          const selection = window.getSelection()
          const range = selection.getRangeAt(0)
          const new_node = document.createTextNode(new_text)
          range.deleteContents()
          range.insertNode(new_node)
          selection.collapseToEnd()
        } else {
          document.selection.createRange().pasteHTML(new_text);
        }

      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-text {
  .ipt {
    color: #666666;
    min-width: 100px;
    &:focus-visible {
      outline: none;
    }
    word-wrap: break-word;
    word-break: break-all;
  }
}
.input-content {
  display: inline;
  padding-right: 5px;
  padding-left: 5px;
  color: #666666;
  &:focus-visible {
    outline: none;
  }
  word-wrap: break-word;
  word-break: break-all;
}
.wink {
  animation: winkOnce 0.7s 1 ease-in-out;
}

@keyframes winkOnce {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
</style>
