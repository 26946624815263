<template>
  <div>
    <header class="header" :style="{ backgroundColor: $route.meta.headerColor ?? '#121212' }">
      <div class="logo" @click="$router.push('/index')">
        <img class="logo-img" src="../../assets/images/logo.png" alt=""/>
      </div>
      <div class="nav">
        <ul>
          <li>
            <a href="" class="utility" v-for="(item, index) in menus" :class="{ active: index == curIndex }"
               :key="index" @click.prevent @contextmenu="handleGetHref($event, item.path, item.permi)">
              <span @click="handlePageChange(item, index)">{{
                  item.name
                }}</span>
            </a>
          </li>
          <li v-hasRole="['leader', 'out_leader', 'manager']" class="dis-none">
            <a href="" class="utility" :class="{ active: idx + menus.length == curIndex }"
               v-for="(itm, idx) in limitMenus" :key="idx" @contextmenu="handleGetHref($event, itm.path)">
              <span @click="handlePageChange(itm, idx + menus.length)">{{ itm.name }}</span>
            </a>

          </li>
        </ul>
      </div>
      <div class="right-menu">
        <div class="search" v-show="$route.path != '/globalsearch/'">
          <input class="search-text" type="text" placeholder="输入搜索关键词" v-model="keywords"
                 @keydown.enter="handleSearch">
          <img class="search-btn" src="@/assets/images/index/search.png" alt="" @click="handleSearch">
        </div>
        <div class="btns">
          <div title="收藏夹" class="star" @click="handlePageChange({ path: '/collect' })"
               v-if="token || Cookies.get(`Portal-Token`)">
            <i class="btn-icon btn-icon-star"></i>
          </div>

          <el-tooltip placement="bottom" effect="light" class="tooltip-menu tooltip-none" popper-class="tool-popper"
                      :disabled="!noticeList.length" v-hasPermi="['portal:notice']">
            <div class="tooltip-content" slot="content">
              <div class="tool-left">
                <div class="tool-type tool-type-active">
                  申请消息 {{ noticeList.length }}
                </div>
              </div>
              <div class="tool-right">
                <div class="tool-contents">
                  <div class="tool-content" v-for="(item, index) in noticeList" :key="index">
                    {{ item.content }}
                  </div>
                </div>
                <div class="tool-more" @click="$router.push('/downAvailable')">
                  查看更多 >
                </div>
              </div>
            </div>
            <div class="notice-bell" title="通知" @click="$router.push('/downAvailable')"
                 v-if="token || Cookies.get(`Portal-Token`)">
              <div class="spot" v-show="notice">{{ notice }}</div>
            </div>
            <!-- <el-button>Top center</el-button> -->
          </el-tooltip>

          <!-- <div class="register">注册</div> -->
          <el-dropdown v-if="token || Cookies.get(`Portal-Token`)" size="middle" class=" right-menu-item hover-effect"
                       trigger="click">
            <div class="avatar-inner">
              <!-- <div class="spot" v-if="notice"></div> -->
              <img :src="avatar" class="avatar"/>
              <div class="nickname" v-if="token || Cookies.get(`Portal-Token`)">{{ nickName }}</div>
            </div>


            <el-dropdown-menu class="drop-menu">
              <router-link to="/userProfile">
                <el-dropdown-item>个人中心</el-dropdown-item>
              </router-link>
              <router-link to="/downAvailable" class="down-item">
                <div class="spot" v-if="notice"></div>
                <el-dropdown-item>申请列表</el-dropdown-item>
              </router-link>
              <router-link to="/sd-allmodel">
                <el-dropdown-item>我的模型库</el-dropdown-item>
              </router-link>
              <el-dropdown-item @click.native="handleLogout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>


          <div class="login" v-else @click="handleLogin">
            <span>登录</span>
          </div>
        </div>
      </div>
    </header>
    <!-- <div class="fixed-menu" v-show="fixedMenuShow && ($store.getters.token || Cookies.get(`Portal-Token`))">
      <div
        class="fixed-menu-item menu-collect"
        @click="$router.push('/collect')"
      >
        <div class="menu-icon">
          <span class="icon-text">收藏夹</span>
          <div class="icon-image"></div>
        </div>
      </div>
      <div
        class="fixed-menu-item menu-forum"
        @mouseenter="handleGetForumList"
        @mouseleave="handleClearForumList"
      >
        <div class="menu-icon">
          <div class="icon-image"></div>
        </div>
        <div class="menu-context">
          <div class="forum-header">
            <span class="title" @click="$router.push('forum')" v-show="showTip"
              >论坛</span
            >
            <span class="desc" @click="$router.push('forum')" v-show="showTip"
              >更多</span
            >
          </div>
          <div
            class="forum-item"
            v-for="(item, index) in contentList"
            :key="index"
            @click="handleViewDetail(item.id)"
          >
            <div class="forum-overview">
              {{ item.title | overviewFilter(item.content) }}
            </div>
            <div class="forum-message">
              <div class="forum-user">
                <span class="forum-name forum-user-item">{{
                  item.publishUserName
                }}</span>
                <span class="forum-time forum-user-item">{{
                  item.publishTime
                }}</span>
              </div>
              <div class="forum-num">
                <div class="forum-content-count-item count-comment">
                  {{ item.commentNum }}
                </div>
                <div class="forum-content-count-item count-like">
                  {{ item.likeNum }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {mapGetters, mapState} from "vuex";
import {adminNotice, adminNoticeList} from "@/api/main/notice";
import {listForum} from "@/api/main/forum";

export default {
  data() {
    return {
      curIndex: 0,
      state: 1,
      Cookies: Cookies,
      timer: null,
      notice: 0,
      menus: [
        {path: "/index", name: "首页"},
        {path: "/inspiration", name: "找灵感"},
        {path: "/calculator-main", name: "做匡算", permi: 'portal:calculator'},
        {path: "/chat", name: "寻答案", permi: 'portal:chat'},
        {path: "/square", name: "去创作", permi: 'portal:aisearch'},
        {path: "/ai-video", name: "创短片"},
        {path: "/companyProfile", name: "找资料", permi: 'portal:companyProfile'},
        {path: "/standardization", name: "找标准化"},
        {path: "/materials", name: "找材料"},
        {path: "/cost", name: "查成本"},
        {path: "/form", name: "找表单"},
        {path: "/computer", name: "去计算"},

        {path: "/forum", name: "论坛"},
      ],
      limitMenus: [{path: "/calculator/list", name: "匡算记录", limit: true}],
      blankPaths: ["/calculator-main", "/collect", "/calculator/list"],
      noticeList: [],
      contentList: [],
      showTip: false,
      fixedMenuShow: true,

      keywords: ''
    };
  },
  created() {
    this.getCookie();
    this.setTimer();
    // this.getAdminNoticeList()
  },
  beforeDestroy() {
    this.clearTimer();
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "device", "nickName", 'permissions']),
    ...mapState(['token'])
  },
  watch: {
    token(ntk) {
      console.log('????????????', ntk)
    }
  },
  filters: {
    overviewFilter(title, content) {
      if (title) {
        return title;
      } else {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        const text = doc.body.textContent.slice(0, 60);
        return text;
      }
    },
  },
  watch: {
    $route: {
      handler(newRoute, oldRoute) {
        if (oldRoute && oldRoute.path == "/login") {
          this.getAdminNotice();
        }
        const index = this.menus.findIndex(
            (item) => item.path == newRoute.path
        );
        this.curIndex = index;
        this.fixedMenuShow = false;
        setTimeout(() => {
          this.fixedMenuShow = true;
        }, 100);
      },
      immediate: true,
    },
  },

  methods: {
    getAdminNoticeList() {
      adminNoticeList().then((res) => {
        this.noticeList = res.rows;
      });
    },
    getCookie() {
      const portalToken = Cookies.get("Portal-Token");
      if (portalToken) {
        this.$loginState = 1;
      } else {
        this.$loginState = 2;
      }
    },
    // 搜索
    handleSearch() {
      const keywords = this.keywords
      this.keywords = ''
      this.$router.push({
        name: "GlobalSearch",
        path: "/globalSearch",
        query: {
          keywords,
        },
        params: {
          keywords,
        },
      });
    },
    getAdminNotice() {
      if (this.$store.getters.token || Cookies.get(`Portal-Token`)) {
        adminNotice().then(({code, data}) => {
          if (code === 200) {
            this.notice = data;
            if (this.notice > 0) {
              this.getAdminNoticeList();
            }
          }
        });
      }
    },
    setTimer() {
      if (this.$store.getters.token || Cookies.get(`Portal-Token`)) {
        if (!this.permissions.includes('portal:notice') && !this.permissions.includes('*:*:*')) {
          return
        }
        adminNotice().then(({code, data}) => {
          if (code === 200) {
            this.notice = data;
            if (this.notice > 0) {
              this.getAdminNoticeList();
            }
          }
        });
      }
      const timer = setInterval(() => {
        if (this.$store.getters.token || Cookies.get(`Portal-Token`)) {
          adminNotice().then(({code, data}) => {
            if (code === 200) {
              this.notice = data;
              if (this.notice > 0) {
                this.getAdminNoticeList();
              }
            }
          });
        }
      }, 30000);
      this.timer = timer;
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    handleGetHref(e, path, permi) {
      if (permi && !this.permissions.includes(permi) && !this.permissions.includes('*:*:*')) {
        e.currentTarget.removeAttribute('href')
        return
      }
      const routerUrl = this.$router.resolve({
        path
      })
      e.currentTarget.setAttribute('href', routerUrl.href)
    },
    handleLogout() {
      this.$confirm("确定退出登录吗？", "退出")
          .then(() => {
            const layout = this.$parent;
            layout.forced = [false, false];
            console.log(this.$parent);
            this.$store.dispatch("LogOut");
            this.curIndex = 0;
            this.$message.info("已退出登录");
            this.$router.push("/login");
          })
          .catch();
    },
    handlePageChange(item) {
      if (this.$store.getters.token || Cookies.get(`Portal-Token`)) {

        if (item.permi && !this.permissions.includes(item.permi) && !this.permissions.includes('*:*:*')) {
          this.$message.info('暂未开放')
          return
        }
      }
      if (item.close) {
        this.$message.info('正在开发中')
        return
      }
      if (this.blankPaths.includes(item.path)) {
        const routerUrl = this.$router.resolve({
          path: item.path,
        });
        // console.log(routerUrl); // ？？？？记录跳转
        window.open(routerUrl.href, "_blank");
      } else {
        this.$router.push(item.path);
      }
    },
    handleLogin() {
      this.$router.push("/login");
    },
    async getForumList() {
      const {rows, total, code} = await listForum({
        pageNum: 1,
        pageSize: 10,
      });
      if (code === 200) {
        this.contentList = rows.slice(0, 4);
      }
    },
    handleGetForumList(e) {
      setTimeout(() => {
        this.showTip = true;
        e.target.style.cursor = "auto";
      }, 200);

      setTimeout(() => {
        this.getForumList();
      }, 500);
    },
    handleClearForumList(e) {
      this.contentList = [];
      this.showTip = false;
      e.target.style.cursor = "pointer";
    },
    handleViewDetail(id, archor) {
      let path = `/forum/detail/${id}`;
      if (archor) path += archor;

      const routerUrl = this.$router.resolve({
        path,
      });

      window.open(routerUrl.href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  font-size: 14px;
  z-index: 3333;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  align-items: center;

  .logo {
    cursor: pointer;

    .logo-img {
      height: 40px;
    }
  }

  .nav {
    padding-left: 38px;
    min-width: 430px;
    flex: 1;
    color: #b3b3b3;

    .utility {
      cursor: pointer;
      color: #b3b3b3;
      padding: 0 11px;

      &:hover {
        color: #fff;
      }

      &.active {
        color: #fff;
      }
    }
  }

  .dis-none {
    display: none;
  }

  .right-menu {
    display: flex;

    .search {
      display: flex;
      align-items: center;
      border: 1px solid #B3B3B3;
      padding: 5px 10px;
      border-radius: 15px;

      .search-text {
        background-color: inherit;
        color: #ccc;
      }

      .search-btn {
        margin-left: 5px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      color: #b3b3b3;
      margin-left: 15px;

      .star {
        cursor: pointer;
        width: 29px;
        height: 29px;
        background-image: url('@/assets/images/star.png');
        background-size: 25px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 15px;
      }

      .register {
        cursor: pointer;
        color: #cdcdcd;
        margin-left: 28px;
        letter-spacing: 2px;
        min-width: 36px;
      }

      .login {
        min-width: 70px;
        padding-left: 1px;
        cursor: pointer;
        box-sizing: border-box;
        letter-spacing: 2px;
        border: 2px solid #fd6702;
        border-radius: 6px;
        text-align: center;
        height: 26px;
        line-height: 26px;
        color: #fd6702;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.notice-bell {
  width: 29px;
  height: 29px;
  margin: 0 18px 0 0px;
  background-image: url(@/assets/images/chalets/bell.svg);
  background-size: contain;
  background-position: center;
  cursor: pointer;
  position: relative;

  .spot {
    font-size: 12px;
    position: absolute;
    height: 16px;
    line-height: 15px;
    padding: 0 3px;
    border-radius: 10px;
    // border-radius: 9px;
    background-color: #ff2a35;
    left: 15px;
    top: -4px;
    text-align: center;
    color: #fcfcfc;
  }
}

.header-black {
  background-color: #121212;
}

.avatar-inner {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  .spot {
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background-color: #ff2a35;
    right: -4px;
    top: 0;
  }

  .nickname {
    padding-left: 10px;
    max-width: 80px;
    color: #ccc;
  }
}

.down-item {
  position: relative;

  .spot {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 7px;
    background-color: #ff2a35;
    right: 13px;
    top: 8px;
  }
}

.avatar {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  cursor: pointer;

  .avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background-position: center;
    background-size: cover;
  }

  .avatar-text {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    box-sizing: border-box;
    color: #f96b26;
    border: 1px solid #f96b26;
    background-color: #e7d6c8;
    text-align: center;
    line-height: 30px;
  }
}

@media screen and (max-width: 1440px) {
  .header {
    font-size: 14px;

    .nav {
      .utility {
        padding: 0 11px;
      }
    }
  }
}

::v-deep .el-dropdown-menu {
  z-index: 3334 !important;
}

::v-deep .el-dropdown-menu__item {
  display: block;
}

.tool-popper {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: none !important;
  padding: 0 !important;
}

::v-deep .el-tooltip__popper {
  z-index: 3334 !important;
  border: none !important;
}

.tooltip-content {
  width: 500px;
  display: flex;
  font-size: 14px;

  .tool-left {
    width: 130px;
    border-right: 1px solid #eeeeee;

    .tool-type {
      padding: 15px 0 15px 10px;

      &.tool-type-active {
        background-color: #efefef;
      }
    }
  }

  .tool-right {
    .tool-content {
      padding: 15px;
      // margin: 0 10px;
      width: 360px;
      border-bottom: 1px solid #eeeeee;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:last-child {
        border-bottom: none;
      }
    }

    .tool-more {
      // margin-top: 20px;
      border-top: 1px solid #eeeeee;
      padding-top: 12px;
      margin-bottom: 12px;
      text-align: center;
      cursor: pointer;
      letter-spacing: 2px;
      color: #b3b3b3;
    }
  }
}

.fixed-menu {
  position: fixed;
  z-index: 3001;
  right: 20px;
  bottom: 125px;

  .fixed-menu-item {
    position: absolute;
    right: 0;
    width: 44px;
    height: 44px;
    border-radius: 44px;
    cursor: pointer;
    transition: all 0.5s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);
    background-color: #ff8c3f;

    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, .05);
    }

    .menu-icon {
      color: #fff;
      text-align: center;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .icon-image {
        margin: 0 auto;
        box-sizing: border-box;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
      }

      .icon-text {
        position: relative;
        top: -1px;
        letter-spacing: 1px;
        padding-left: 1px;
      }
    }
  }

  .menu-collect {
    bottom: 50px;

    .icon-image {
      background-image: url(@/assets/images/collect/collect.png);
    }

    .icon-text {
      font-size: 12px;
      display: none;
    }

    &:hover {
      .icon-image {
        display: none;
      }

      .icon-text {
        display: block;
      }
    }
  }

  .menu-forum {
    z-index: 3002;
    bottom: 0;

    .icon-image {
      background-image: url(@/assets/images/forum/menu.png);
    }

    &:hover {
      border-radius: 5px;
      overflow: hidden;
      width: 320px;
      height: 420px;
      background-color: #fff;

      .menu-icon {
        display: none;
      }

      .menu-context {
        display: block;
      }
    }
  }

  .forum-header {
    background: linear-gradient(to right, #ff8c40, #ffdbc2);
    height: 38px;
    line-height: 38px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    .title {
      color: #fff;
      font-size: 16px;
      letter-spacing: 1px;
      cursor: pointer;
    }

    .desc {
      color: #fe7c27;
      font-size: 12px;
      letter-spacing: 1px;
      cursor: pointer;
    }
  }

  .forum-item {
    padding: 12px 10px 8px 12px;
    margin: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f5f5;
    position: relative;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &::before {
      content: "·";
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      position: absolute;
      left: -3px;
      color: #b3b3b3;
    }

    .forum-overview {
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
      font-size: 14px;
      color: #333;
      line-height: 22px;
    }

    .forum-message {
      line-height: 26px;
      display: flex;
      justify-content: space-between;
    }

    .forum-user-item {
      color: #bcbcbc;
      font-size: 12px;
      padding-right: 8px;

      &.forum-name {
        letter-spacing: 1px;
      }
    }
  }

  .menu-icon {
    display: block;
  }

  .menu-context {
    display: none;
  }
}

.forum-content-count-item {
  width: 30px;
  color: #b5b4b4;
  margin-left: 5px;
  padding-left: 25px;
  position: relative;
  line-height: 30px;
  cursor: pointer;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 3px;
    top: 6px;
  }

  &.count-comment::before {
    background-image: url(@/assets/images/forum/comment.svg);
  }

  &.count-like::before {
    background-image: url(@/assets/images/forum/hearts.svg);
  }

  &.count-like.liked {
    color: #f4670b;

    &::before {
      background-image: url(@/assets/images/forum/hearts-fill.svg);
    }
  }
}
</style>
@/api/main/notice@/api/main/forum
