<template>
  <div>
    <!-- <router-link to="index">index</router-link>
        <router-link to="test">test</router-link> -->
    <app-header />
    <app-main />
    <app-footer v-if="!$route.meta.nofooter" />
    <el-dialog
      class="dialog"
      title="提示"
      :visible.sync="forced[0] || forced[1]"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
    >
      <div v-show="forced[0]">
        <span class="form-title">您仍在使用初始密码，请更换新的密码</span>
        <el-form
          ref="form"
          :model="passwordBody"
          :rules="passwordRules"
          label-width="80px"
          label-position="left"
          :hide-required-asterisk="true"
        >
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input
              v-model="passwordBody.oldPassword"
              placeholder="请输入旧密码"
              type="password"
              show-password
            />
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              v-model="passwordBody.newPassword"
              placeholder="请输入新密码"
              type="password"
              show-password
            />
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input
              v-model="passwordBody.confirmPassword"
              placeholder="请确认密码"
              type="password"
              show-password
            />
          </el-form-item>
        </el-form>
      </div>
      <div v-show="!forced[0] && forced[1]">
        <span class="form-title">您的个人信息不完整，请填写以下信息</span>
        <el-form
          ref="form2"
          :model="userProfile"
          :rules="userRules"
          label-position="left"
          label-width="60px"
          :hide-required-asterisk="true"
        >
          <el-form-item label="手机" prop="phonenumber">
            <el-input v-model="userProfile.phonenumber" maxlength="11" />
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="userProfile.email" maxlength="50" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSumbitChange">{{
          forced[0] && forced[1] ? "下一步" : "确定"
        }}</el-button>
      </span>
    </el-dialog>
    <Version></Version>
  </div>
</template>

<script>
import AppHeader from "./Header.vue";
import AppMain from "./Appmain.vue";
import AppFooter from "./Footer.vue";
import Cookies from "js-cookie";
import Version from './Veision.vue'
import {
  getUserProfile,
  updateUserPwd,
  updateUserProfile,
} from "@/api/pub/user";
export default {
  components: {
    AppHeader,
    AppMain,
    AppFooter,
    Version
  },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.passwordBody.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };

    return {
      a: true,
      dialogVisible: true,
      forced: [false, false], // 强制修改密码 | 强制填写手机号和邮箱
      userProfile: {},
      userRules: {
        email: [
          { required: true, message: "邮箱地址不能为空", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        phonenumber: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
      passwordBody: {
        oldPassword: "123456",
      },
      passwordRules: {
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    const token = Cookies.get("Portal-Token");
    const password = Cookies.get("portal-password");
    if (token && password == "123456") {
      this.$set(this.forced, 0, true);
      this.passwordBody.oldPassword = password;
    }
    if (token) {
      this.handleGetUserProfile();
    }
  },

  methods: {
    handleGetUserProfile() {
      getUserProfile().then(({ data }) => {
        this.userProfile = data;
        const hasOutRole = this.userProfile.roles.find(item => item.roleKey === "out_use")
        if(hasOutRole) return
        const { phonenumber, email } = data;
        if (!phonenumber || !email) {
          this.$set(this.forced, 1, true);
        }
      });
    },
    async handleSumbitChange() {
      if (this.forced[0]) {
        // 修改密码逻辑
        const val = await new Promise((resolve) => {
          this.$refs["form"].validate(async (valid) => {
            if (valid) {
              if (
                this.passwordBody.oldPassword == this.passwordBody.newPassword
              ) {
                this.$message.error("新密码与旧密码不能相同");
                resolve(false);
              }

              const res = await updateUserPwd(
                this.passwordBody.oldPassword,
                this.passwordBody.newPassword
              );
              if (res.code == 200) {
                Cookies.set("portal-password", this.passwordBody.newPassword);
                this.$message.success("密码更新成功");
                if (!Object.keys(this.userProfile).length) {
                  this.handleGetUserProfile();
                }
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          });
        });

        if (val == false) {
          return;
        }
        this.$set(this.forced, 0, false);
        return;
      }

      if (!this.forced[0] && this.forced[1]) {
        // 修改基本信息逻辑
        const val2 = await new Promise((resolve) => {
          this.$refs["form2"].validate(async (valid) => {
            if (valid) {
              const res = await updateUserProfile(this.userProfile);
              if (res.code === 200) {
                this.$message.success("修改个人信息成功");
                resolve(true)
              } else {
                resolve(false)
              }
            } else {
              resolve(false)
            }
          });
        });
        if (val2 == false) {
          return;
        }
        this.$set(this.forced, 1, false);
      }
    },
  },
  props: {
    hasfooter: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  margin-top: 20px;
}
</style>
@/api/pub/user