<template>
  <div class="my-bread container">
    <div class="my-breadcrumb">
      <!-- <span v-for="(route, index) in routeList" :key="index">
        <a
          class="a-bread"
          :title="route.name"
          :class="[{ 'a-active': index != len - 1 }]"
          @click="
            index === len - 1 ? (e) => e.preventDefault() : goRouter(route.path, route.back)
          "
        >
          {{ route.name || "--" }}
        </a>
        <template v-if="index != len - 1">
          <span class="seperator">/</span>
        </template>
      </span> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    routeList: {
      default: () => {
        return [];
      },
    },
  },
  methods: {
    goRouter(path, back) {
      this.$router.push({ path });
    },
  },
  computed: {
    len() {
      // 面包屑长度
      return this.routeList.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-bread {
  .my-breadcrumb {
    // padding-top: 44px;
    padding-top: 22px;
    color: #e8d7c9;
    font-size: 14px;
    .a-bread {
      color: #e8d7c9;
      &.a-active {
        cursor: pointer;
        &:hover {
          color: #fd6702;
        }
      }
    }
  }
}
</style>
