<template>
  <div class="mask">
    <div class="mask-content">
      <div class="tip-text">
        <img v-if="tipText == '发布成功'" :src="yes" alt="" />
        <img v-if="tipText == '发布失败'" :src="no" alt="" />
        {{ tipText }}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
// 通用提示框
export default {
  props: {
    tipText: {
      type: String,
      default: "",
    },
  },
  setup() {
    let state = reactive({
      yes: require("@/assets/images/imgs/yes.png"),
      no: require("@/assets/images/imgs/no.png"),
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="scss">
.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  .mask-content {
    min-width: 194px;
    padding: 0 23px;
    height: 60px;
    background: #373737;
    border: 2px solid #4f4f4f;
    border-radius: 10px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #e9e9e9;

    .tip-text {
      display: flex;
      align-items: center;
      img {
        margin-right: 24px;
      }
    }
  }
}
</style>
