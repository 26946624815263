<template>
  <div @click="handleClose()">
    <div class="mask" :class="{'mask-show': maskShow}">
      <div class="tip-main" :class="{'tip-main-show': tipMainShow}">
        <i class="tip-icon"></i>
        <span>已向负责人申请{{content}}权限，点击右上角通知可查看审核进度</span>
        <i class="el-icon-circle-close close-btn"></i>
      </div>
    </div>
    <div class="ber-icon" :class="{'ber-icon-large': berIconLarge} "></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maskShow: false,
      tipMainShow: false,
      content: '',
      berIconLarge: false
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    message(pointerEvent) {
      this.arc(pointerEvent)
      this.maskShow = true
      this.tipMainShow = true
      setTimeout(() => {
        this.tipMainShow = false
      }, 2000)
      setTimeout(() => {
        this.maskShow = false
      }, 2000)
    },
    handleClose() {
      this.tipMainShow = false
      this.maskShow = false
    },
    arc(pointerEvent) {
      const x = pointerEvent.clientX;
      const y = pointerEvent.clientY;

      const icon = document.querySelector('.ber-icon');
      icon.style.display = 'block'
      icon.style.left = x + 'px';
      icon.style.top = y + 'px';

      this.berIconLarge = true
      setTimeout(() => {
        this.berIconLarge = false
      }, 900)

      const target = document.querySelector('.notice-bell');
      const targetRect = target.getBoundingClientRect();
      const targetX = targetRect.left + (targetRect.width / 2);
      const targetY = targetRect.top + (targetRect.height / 2);

      const controlX = x;
      const controlY = targetY;

      icon.style.left = targetX + 'px';
      icon.style.top = targetY + 'px';
      icon.style.transform = `translate(-50%, -50%) quadratic-bezier(${x}px ${y}px, ${controlX}px ${controlY}px, ${targetX}px ${targetY}px)`;
      
      setTimeout(function() {
        icon.style.display = 'none'
      }, 1000);
    }
  }
}
</script>
<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 555;
  align-items: center;
  display: none;
}
.mask-show {
  display: flex;
}

.tip-main {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin: 0 auto;
  width: 700px;
  height: 100px;
  background-color: #FFFFFF;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #E55E04;
  line-height: 25px;
  position: relative;
  .close-btn {
    position: absolute;
    font-size: 25px;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: #E8E8E8;
  }
  .tip-icon {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(@/assets/images/chalets/wow.png);
    background-size: contain;
    position: relative;
    top: 1px;
    margin-right: 15px;
  }
}
.tip-main-show {
  opacity: 1;
}
.ber-icon {
  width: 0px;
  height: 0px;
  background-image: url(@/assets/images/chalets/ber.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  display: none;
  z-index: 1003;
  transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ber-icon-large {
  width: 40px;
  height: 40px;
}
</style>