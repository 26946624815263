<template>
  <div class="scroll-view">
    <div class="left arrow" @click="scrollLeft" v-show="menuOverFlow && !leftEnd"></div>
    <div class="right arrow" @click="scrollRight" v-show="menuOverFlow && !rightEnd"></div>
    <div class="scroll-bar" :style="{ 'left': left + 'px' }">
      <slot name="content"></slot>
    </div>
    
  </div>
</template>

<script>

export default {
  data() {
    return {
      containerWidth: 1200,
      erd: null,
      scrollBar: null,
      left: 0,
      leftEnd: true,
      rightEnd: false
    }
  },
  props: {
    contentWidth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    menuOverFlow() { // 菜单宽度是否超出container
      return this.contentWidth > this.containerWidth ? true : false
    }
  },
  methods: {
    scrollLeft() {
      const offsetAtPresent = this.scrollBar.offsetLeft // 当前偏移宽度
      const rightOffset = -offsetAtPresent > this.containerWidth ? this.containerWidth : -offsetAtPresent
      this.left += rightOffset
      this.arrowUpdate()
    },
    scrollRight() {
      const offsetRange = this.contentWidth - this.containerWidth // 可偏移宽度
      const offsetAtPresent = this.scrollBar.offsetLeft // 当前偏移宽度
      const rightOffset = offsetRange + offsetAtPresent > this.containerWidth ? this.containerWidth : offsetRange + offsetAtPresent
      this.left -= rightOffset
      this.arrowUpdate()
    },
    arrowUpdate() {
      console.log(this.contentWidth, this.containerWidth)
      const offsetRange = this.contentWidth - this.containerWidth // 可偏移宽度
      console.log(offsetRange, this.left)
      this.leftEnd = this.left >= 0 ? true: false
      this.rightEnd = offsetRange + this.left > 0 ? false: true
    }
  },
  mounted() {
    const elementResizeDetectorMaker = require("element-resize-detector");
    this.erd = elementResizeDetectorMaker()
    // 获取container宽度，判断是否超出
    this.erd.listenTo(document.querySelector('.scroll-view'), (ele) => {
      this.containerWidth = ele.offsetWidth
      if(this.contentWidth != 0) {
        this.arrowUpdate()
      }
    })

    // 获取scrollbar
    this.scrollBar = document.querySelector('.scroll-bar')
  },
  beforeDestroy() {
    this.erd.uninstall(document.querySelector('.scroll-view'))
  }
}
</script>

<style lang="scss" scoped>
.scroll-view {
  position: relative;
  overflow: hidden;
  height: 90px;
  .arrow {
    height: 100%;
    width: 35px;
    background-color: #fff;
    position: absolute;
    z-index: 10;
    background-image: url('@/assets/images/pub/left-dark.png');
    background-size: 27px 49px;
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-shadow: 20px 0px 10px rgba(255, 255, 255, 0.6);
    &:hover {
      filter: brightness(105%);
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
      transform: rotate(180deg);
    }
  }
  .scroll-bar {
    position: absolute;
    transition: .5s;
  }
  ::-webkit-scrollbar {
    // width: 0px;
    // height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #E6E6E6;
  }
}
</style>