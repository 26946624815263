<template>
  <div class="my-headline container">
    <h2 class="my-head">
      <span class="jump-head" v-show="linkhead.url" @click="$router.push(linkhead.url)">{{ linkhead.name }} > </span>
      <span>{{ headline }}</span>
    </h2>
    <div class="search" v-if="searchBar">
      <div class="search-area">
        <el-tag v-if="filterTagName" closable @close="handleClearParams">{{
          filterTagName
        }}</el-tag>
        <el-select popper-class="select-popper" v-model="selectId" placeholder="选择部门" v-if="hasSelect" class="filter-select" @change="handleSelectChange">
          <el-option
            v-for="item in selectOptions"
            :key="item[selectValue]"
            :label="item[selectKey]"
            :value="item[selectValue]">
          </el-option>
        </el-select>
        <el-input
          size="small"
          :placeholder="searchHolder"
          v-model="searchValue"
          class="search-input"
          type="text"
          @keydown.enter.native="handleSearch()"
        ></el-input>
      </div>

      <el-button
        size="small"
        class="search-btn"
        @click="
          handleSearch();
        "
        >搜索</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        searchValue: '',
        selectId: 0   
    }
  },
  props: {
    headline: {
      default: "",
    },
    searchBar: {
      default: false,
    },
    searchHolder: {
      default: "",
    },
    filterTagName: {
      default: null,
    },
    hasSelect: {
      default: false
    },
    selectOptions: {
      default: () => {
        return []
      }
    },
    selectKey: {
      default: 'label'
    },
    selectValue: {
      default: 'value'
    },
    linkhead: {
      default: () => {
        return {}
      }
    }
  },
  methods: {
    handleSearch() {
      this.$emit('handleSearch', this.searchValue)
    },
    handleClearParams() {
      this.$emit('handleClearParams')
    },
    handleSelectChange(deptId) {
      this.$emit('handleSelectChange', deptId)
    }
  }
};
</script>
<style lang="scss" scoped>
.my-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;
}
.my-head {
  margin: 13px 0 13px;
  font-size: 23px;
  font-weight: normal;
  letter-spacing: 4px;
  color: #fc6804;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search {
    display: flex;
    .search-area {
      box-sizing: border-box;
      height: 36px;
      display: flex;
      align-items: center;
      background-color: #fff8f2;
      border: 1px solid #cdbaab;
      border-radius: 4px;
      color: #cdbaab;
      width: 450px;
    }
    .search-input {
      ::v-deep .el-input__inner {
        background-color: #fff8f2;
        border: none;
        color: #cdbaab;
        &[type="text"]::placeholder {
          color: #cbbcb2;
        }
      }
    }
    .search-btn {
      font-size: 16px;
      font-weight: bold;
      margin-left: 10px;
      border: none;
      border-radius: 6px;
      background-color: #F96B26;
      color: #ffffff;
      letter-spacing: 4px;
      padding-left: 18px;
      padding-right: 14px;
      &:hover {
        opacity: 0.9;
      }
    }
  }
.filter-select {
  height: 100%;
  background-color: #CCBAAB;
  ::v-deep .el-input {
    height: 100%;
    background-color: #CCBAAB;
  }
  ::v-deep .el-input__inner {
    font-size: 14px;
    height: 100%;
    background-color: #CCBAAB;
    border: none;
    color: #fff;
    text-align: center;
    width: 150px;

  }
  
  ::v-deep .el-input__inner::placeholder {
    color: #fff;
  }
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #fff;
  }
  ::v-deep .el-input__inner::-moz-placeholder {
    color: #fff;
  }
  ::v-deep .el-input__inner::-ms-input-placeholder {
    color: #fff;
  }
  ::v-deep .el-select__caret {
    color: #fff;
  }
}
.jump-head {
  cursor: pointer;
}
</style>
