<template>
  <div class="colleck-mask" v-if="isShow">
    <div class="mask">
      <div class="dialog">
        <div class="title">选择收藏夹</div>
        <div class="close" @click="$emit('collectFinish')">×</div>
        <div class="search-bar">
          <el-input
            class="search-input"
            v-model="queryParams.keyword"
            placeholder="输入收藏夹名称搜索"
            suffix-icon="el-icon-search"
            @input="handleKeyWordChange"
          ></el-input>
        </div>
        <div class="create-quick">
          <div class="create-title">收藏到：</div>
          <div class="create-list">
            <div
              class="create-item"
              v-for="(item, index) in collectList"
              :key="index"
            >
              <div class="left">
                <div class="icon"></div>
                <span class="name">{{ item.collectName }}</span>
              </div>
              <div class="right">
                <el-button class="collect-button" type="primary" @click="handleAddFile(item.id)"
                  >收藏</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="create-collect">
          <div class="create-icon" @click="handleAddCollect"></div>
          <div class="create-title">创建新收藏夹</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listUserCollect, collectAdd, collectFileAdd } from "@/api/main/collect";
import { getInfo } from "@/api/pub/login";

export default {
  name: "CollectMask",
  props: ['isShow', 'fileId', 'preview', 'module', 'moduleId'],
  data() {
    return {
      bagId: null,
      collectList: [],
      queryParams: {
        userId: null,
        keyword: null,
      },
    };
  },
  created() {
    this.getUserCollection();
  },
  methods: {
    async getUserCollection() {
      let userId = this.$store.state.user.userId;
      if (!userId) {
        this.$store.dispatch("GetInfo");
        console.log(this.$store.getters);
        userId = this.$store.getters.userId;
      }
      if (!userId) {
        const { code, user } = await getInfo();
        if (code == 200) {
          userId = user.userId;
        }
      }
      this.queryParams.userId = userId;
      this.getCollectList();
    },
    handleKeyWordChange() {
      this.getCollectList();
    },
    async getCollectList() {
      const { code, data } = await listUserCollect(this.queryParams);
      if (code == 200) {
        this.collectList = data;
      }
    },
    handleAddCollect() {
      this.$prompt("输入文件夹名称", "创建文件夹", {
        inputErrorMessage: "",
        inputValidator: (value) => {
          if (!value) {
            return "输入不能为空";
          }
          const name = value.trim();
          const index = this.collectList.findIndex(
            (item) => item.collectName == name
          );
          if (index != -1) {
            return "该收藏夹已存在，请重新命名！";
          }
        },
      })
        .then(({ value }) => {
          this.addCollection(value);
        })
        .catch();
    },
    async addCollection(name) {
      const { code, msg } = await collectAdd({
        collectName: name,
      });
      if (code == 200) {
        this.$message.success(msg);
        this.getCollectList();
      }
    },
    async handleAddFile(collectId) {
      const {msg, code} = await collectFileAdd({
        collectId,
        fileId: this.fileId,
        moduleId: this.moduleId,
        module: this.module,
        preview: this.preview
      })
      if(code === 200) {
        this.$message.success(msg)
      }
      this.$emit('collectFinish')
    }
  },
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 11, 11, 0.7);
  z-index: 200;
  .dialog {
    position: absolute;
    width: 300px;
    background: #ffffff;
    border-radius: 10px;
    top: 180px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 21px 15px;
    .close {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      position: absolute;
      right: 15px;
      top: 13px;
      cursor: pointer;
    }
  }
  .preview {
    width: 100%;
    height: 185px;
    border-radius: 6px;
    .preview-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
  .name-input {
    margin-top: 4px;
    .textarea {
      font-size: 14px;
      color: #5b5b5b;
    }
  }
  .name-input {
    margin-top: 4px;
    .textarea {
      font-size: 14px;
      color: #5b5b5b;
    }
  }
  .tag-input {
    margin-top: 8px;
    .textarea {
      font-size: 12px;
      color: #5b5b5b;
    }
    ::v-deep .el-textarea__inner {
      background-color: #f8f8f8;
      border-color: #f8f8f8;
    }
  }
  .subtitle {
    margin-top: 11px;
    color: #5b5b5b;
    font-size: 14px;
    .sub {
      font-size: 12px;
    }
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #131313;
    letter-spacing: 1px;
  }
  .search-bar {
    margin-top: 18px;
    width: 100%;
    .search-input {
      width: 100%;
      ::v-deep .el-input__inner {
        border-color: #bdbdbd;
        padding: 0px 20px;
        height: auto;
      }
    }
  }
  .create-quick {
    margin-top: 24px;
    border-bottom: 2px solid #DDDDDD;
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #b0b0b0;
    }
    .create-title {
      color: #3c3c3c;
      font-size: 14px;
    }

    .create-list {
      margin-top: 8px;
      font-size: 12px;
      color: #a3a3a3;
      height: 330px;
      overflow: auto;

      .create-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        color: #a3a3a3;
        padding: 10px 7px;
        .left {
          display: flex;
          align-items: center;
          .icon {
            width: 15px;
            height: 14px;
            background-image: url(@/assets/images/collect/folder-grey.png);
            background-size: contain;
            background-repeat: no-repeat;
          }
          .name {
            padding-left: 10px;
            font-size: 14px;
          }
        }
        .right {
          .collect-button {
            border-radius: 13px;
            font-size: 14px;
            color: #fffbfb;
            letter-spacing: 1px;
            padding: 5px 14px 5px 15px;
          }
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
  .create-collect {
    display: flex;
    align-items: center;
    margin-top: 12px;
    .create-icon {
      cursor: pointer;
      width: 32px;
      height: 32px;
      background-image: url(@/assets/images/collect/add.png);
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
    .create-title {
      margin-left: 13px;
      letter-spacing: 1px;
      font-size: 14px;
      color: #FC6804;
    }
  }
  ::v-deep .el-textarea__inner {
    padding: 5px 10px;
    border-color: #dadada;
  }
  ::v-deep textarea::-webkit-input-placeholder {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
  }
  textarea::-moz-placeholder {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
  }
  textarea:-moz-placeholder {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
  }
  textarea:-ms-input-placeholder {
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
  }
}
</style>
@/api/main/collect@/api/pub/login