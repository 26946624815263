<template>
  <div class="menu container">
    <div class="left pager" v-show="menuOverFlow" @click="handleChangeOffset(-1)">
      <img src="@/assets/images/pub/left.png" alt="">
    </div>
    <div class="right pager" v-show="menuOverFlow" @click="handleChangeOffset(1)">
      <img src="@/assets/images/pub/right.png" alt="">
    </div>
    <div class="my-menus">
      <div class="menu-bar">  
        <div
          class="my-menu-item"
          :class="{ active: current == index }"
          v-for="(item, index) in menuList"
          :key="index"
          @click="handleChangeMenu(index, item)"
        >
          <img class="my-menu-icon" :src="item[icon]" alt="" />
          <h3 class="my-menu-title">{{ item.name }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: 0,
      containerWidth: 1200,
      erd: null
    }
  }, 
  props: {
    icon: {
      default: 'imgUrl'
    },
    menuList: {
      default: () => [],
    }
  },
  methods: {
    handleChangeMenu(index, item) { // 切换菜单项
      this.current = index;
      // 接口调用
      if(item.id) {
        this.$emit('menuChange', index, item.id)
      } else {
        this.$emit('menuChange', index)
      }
      
    },
    handleChangeOffset(direct) {
      const offsetRange = this.menuBarTotalWidth - this.containerWidth + 20 // 可偏移宽度
      const menuBar = document.querySelector('.menu-bar')
      const offsetAtPresent = menuBar.offsetLeft // 当前偏移量
      if(direct > 0) {
        const rightStep = offsetRange + offsetAtPresent > 150 ? 150 : offsetRange + offsetAtPresent
        menuBar.style.left = offsetAtPresent - rightStep * direct + 'px'
      } else {
        const leftStep = -offsetAtPresent > 150 ? 150 : -offsetAtPresent
        menuBar.style.left = (offsetAtPresent + leftStep) + 'px'
      }
    }
  },
  computed: {
    menuBarTotalWidth() { // 菜单宽度
      return (114 + 20) * this.menuList.length
    },
    menuOverFlow() { // 菜单宽度是否超出container
      return this.menuBarTotalWidth > this.containerWidth ? true : false
    }
  },
  mounted() {
    const elementResizeDetectorMaker = require("element-resize-detector");
    this.erd = elementResizeDetectorMaker()

    // 获取container宽度，判断菜单宽度是否超出
    this.erd.listenTo(document.querySelector('.container'), (ele) => {
      this.containerWidth = ele.offsetWidth
    })
  },
  beforeDestroy() {
    this.erd.uninstall(document.querySelector('.container'))
  }
};
</script>
<style lang="scss" scoped>

.menu {
  position: relative;
  .pager {
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.left {
      left: -50px;
    }
    &.right {
      right: -50px;
    }

    img {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }
}
.my-menus {
  position: relative;
  border-top: 2px solid #f5e7dc;
  border-bottom: 2px solid #f5e7dc;
  padding: 12px 0 22px 0;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  min-height: 152px;
  .menu-bar {
    display: flex;
    position: relative;
    padding-left: 20px;
    left: 0px;
    transition: left .2s;
    .my-menu-item {
      flex-shrink: 0;
      width: 114px;
      height: 118px;
      border-radius: 5px;
      float: left;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.active, &:hover {
        background-color: #fff;
        box-shadow: 0px 5px 15px 2px rgba(203, 162, 127,0.15);
        position: relative;
        cursor: pointer;
      }
      &.active:before, &:hover:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: -15px;
        z-index: 1;
        border: 7px solid #fff;
        border-top-width: 10px;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
      }

      & + .my-menu-item {
        margin-left: 20px;
      }
      .my-menu-icon {
        width: 65px;
        height: 65px;
        z-index: 2;
      }
      .my-menu-title {
        margin-top: 12px;
        margin-bottom: 0;
        color: #4d4d4d;
        font-size: 14px;
        z-index: 2;
      }
      &::after {
        content: "";
        width: 114px;
        height: 81px;
        position: absolute;
        background-color: #fff;
        border-radius: 5px;
        bottom: 0;
        z-index: 1;
      }
    }
  }
}
</style>
