<template>
  <div class="select-model-wrapper">
    <el-dialog
      custom-class="select-wrapper"
      :visible.sync="dialogVisible"
      width="740px"
      :before-close="handleClose1"
    >
      <template #title>
        <div class="my-header">
          <div class="title">模型</div>
          <el-input
            class="input-with-select"
            v-model.trim="searchName"
            placeholder="搜索"
            @input="filterList"
            clearable
          />
        </div>
      </template>

      <div class="select-list">
        <div
          :title="item.descrption"
          v-for="item in modelListCopy"
          :key="item.id"
          class="img"
          :class="{'item-actived': info && info.id == item.id}"
          :style="{'background-image': `url('${item.imageUrl}')`}"
          @click="selectItem(item)"
        >
          <div class="loar-count" v-if="item.loraCount > 0">
            {{ item.loraCount }}
          </div>
          
          <div class="name" :title="item.modelAlias || item.loraAlias">
            {{ item.modelAlias || item.loraAlias }}
          </div>
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button size="large" @click="handleClose1" class="btn no-btn"
            >取消</el-button
          >
          <el-button
            size="large"
            type="primary"
            class="btn yes-btn"
            @click="handleClose"
            >确认</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from "vue";

import { debounceFun, throttleFun } from "@/utils/commonFn";

export default {
  props: {
    modelList: {},
    modelFlag: {}, // 糟粕，懒得删了
    selectInfo: {}, // 选择的checkpoints
  },
  setup(props, { emit }) {
    let state = reactive({
      dialogVisible: true,
      defaultImg: require("@/assets/images/imgs/default.png"),
      info: null,
      searchName: null, // 名称
      modelListCopy: null,
    });

    onMounted(() => {});

    watch(
      () => props.modelList,
      (newval) => {
        if (newval) {
          if (props.selectInfo) {
            state.info = props.selectInfo;
          }
          setTimeout(() => {
            state.modelListCopy = JSON.parse(JSON.stringify(props.modelList));
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    const handleClose = () => {
      state.info.modelFlag = props.modelFlag;
      emit("closeDialog", state.info);
    };
    const handleClose1 = () => {
      emit("closeDialog1");
    };
    const selectItem = (info) => {
      // emit("selectItem",info);
      state.info = info;
    };

    const filterList = debounceFun(() => {
      // console.log(state.searchName, "xxxxxx");

      state.modelListCopy = props.modelList.filter((item) => {
        return (
          (item.modelAlias && item.modelAlias.includes(state.searchName)) ||
          (item.loraAlias && item.loraAlias.includes(state.searchName))
        );
      });
    });

    return {
      ...toRefs(state),
      handleClose,
      handleClose1,
      selectItem,
      filterList,
    };
  },
};
</script>

<style scoped lang="scss">
.select-model-wrapper {
  :deep(.el-dialog) {
    max-height: 684px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #333333;
    border: 2px solid #212121;
    box-shadow: 0px 13px 27px 5px rgba(18, 18, 18, 0.81);
    border-radius: 10px;

    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;
      .el-dialog__close {
        font-size: 20px;
      }
    }
    .my-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 0;
      border-bottom: 2px solid #2c2c2c;
      padding-right: 20px;
      .title {
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
      }

      .input-with-select {
        width: 300px;
        height: 40px;
        border-radius: 20px;
        margin-right: 30px;
        background: #2c2c2c;
        border: none !important;
        .el-input__wrapper {
          border-radius: 20px !important;
          background: #2c2c2c !important;
        }
        .el-input__wrapper,
        .el-textarea__inner {
          border: 2px solid #2c2c2c !important;
        }
      }
    }
    .el-dialog__header {
      padding: 0 30px 0px 30px;

      .el-dialog__close {
        background: #2c2c2c;
        border-radius: 50%;
        padding: 6px;
      }
    }
    .el-dialog__body {
      padding: 22px 30px;
      min-height: 300px;
      flex-grow: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .dialog-footer {
      color: #fff;
      button {
        width: 100px;
        span {
          color: #747373;
          font-size: 20px;
        }
      }
      .btn {
        width: 124px;
        height: 35px;
        border: none;
        padding: 0;
        &:hover {
          filter: brightness(110%);
        }
        line-height: 35px;
        &.no-btn {
          background-color: #434242;
          span {
            color: #747373;
          }
        }
        &.yes-btn {
          background-color: #fc6804;
          
          font-size: 20px;
          span {
            color: #fff;
          }
        }
      }
    }
  }
  .select-wrapper {
    .select-list {
      /* flex-grow: 1; */
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      .img {
        width: 100px;
        height: 100px;

        background: #292929;
        border: 2px solid #4c4c4c;
        box-sizing: border-box;
        border-radius: 12px;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .loar-count {
          position: absolute;
          width: 20px;
          height: 20px;
          line-height: 20px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 6px;
          top: 4px;
          right: 4px;
          text-align: center;
          // color: #ff8c3f;
          color: #fff;
        }
        // mask: url(@/assets/images/imgs/bg_mask.png);
        &.item-actived {
          border: 2px solid #fc6804;
        }
        .name {
          width: 86px;
          position: absolute;
          z-index: 9999;
          bottom: 0px;
          border-radius: 10px;
          color: #e9e9e9;
          padding: 5px;
          padding-top: 40px;
          background: linear-gradient(to top, #2e2e2e 0%, #2e2e2e 25%, #2e2e2edb 50%, #2e2e2e63 75%, transparent 100%);

          font-weight: 400;
          font-size: 12px;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
        &.img-actived {
          border: 1px solid #fc6804;
        }
        cursor: pointer;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
